import styles from './NavExitStep.module.css';
import { NavExitStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import { updateStep } from '../../../../../../controllers/ConversationEditorController/ConversationEditorActions';
import Toggle from '../../../../../../shared/Toggle/Toggle';
import { useDispatch } from 'react-redux';

interface NavExitStepProps {
    step: NavExitStep;
}

function NavExitStepComponent({ step }: NavExitStepProps): JSX.Element {
    const dispatch = useDispatch();
    return (
        <>
            <div className={styles.label}>
                Crear un caso cerrado:
                <div className={styles.toggleContainer}>
                    <Toggle
                        checked={!!step.createClosedTicket}
                        onChange={(createClosedTicket: boolean): void => {
                            dispatch(
                                updateStep({
                                    ...step,
                                    createClosedTicket,
                                })
                            );
                        }}
                    />
                </div>
            </div>
        </>
    );
}

export default NavExitStepComponent;
