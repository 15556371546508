import React, { useCallback, useContext, useEffect, useState } from 'react';
import Dialog from '../../Dialog/Dialog';
import {
    PayloadEditorActions,
    PayloadEditorContext,
    usePayloadEditorDispatch,
} from '../../../controllers/PayloadEditorController/PayloadEditorSlice';
import {
    DraftEntityData,
    isMappableEntityData,
} from '../../../@Types/Draft/DraftEntityData';
import PayloadEditor, { PayloadEditorConceptsContext } from '../PayloadEditor';
import {
    DraftEntityDataMappingTypes,
    DraftEntityDataTypes,
} from '../../../constants/Draft/DraftEntityDataTypes';
import { Payload } from '../../../@Types/Payload';
import produce from 'immer';
import { DraftContext } from '../../../@Types/Draft/DraftContext';
import { DraftMappings } from '../../../@Types/Draft/DraftMappings';
import EntityEditorContainer from './EntityEditorContainer';
import ConditionTypes from '../../../constants/Conditions/ConditionTypes';
import { ConditionContext } from '../../ConditionEditor/ConditionEditor';
import {
    calcCondition,
    clearConditionEditor,
} from '../../../controllers/ConditionEditorController/ConditionEditorSlice';
import { Condition } from '../../../@Types/ConditionTypes/Condition';
import styles from './EntityEditor.module.css';
import DateFormatPicker from '../../@Pickers/DateFormatePicker/DateFormatPicker';
import { TicketPropertyTypes } from '../../../constants/TicketPropertyTypes';
import RoundedTextField from '../../RoundedTextField/RoundedTextField';
import Toggle from '../../Toggle/Toggle';
import { IdGenericEditorContext } from '../../GenericFormEditor/GenericFormEditor';
import {
    useAppSelector,
    useClientEntity,
    useCurrentProject,
} from '../../../hooks';
import IntegrationErkValuesPicker from '../../Integrations/IntegrationErkValuesPicker/IntegrationErkValuesPicker';
import {
    isMultipleClientRelationshipEntityData,
    isMultipleEntityValueEntityData,
} from '../../../controllers/PayloadEditorController/PayloadFunctions';

interface EditEntityComponentProps {
    data: DraftEntityData;
    context?: DraftContext;
    mappings?: DraftMappings;
    conditions: { types: ConditionTypes[]; context?: ConditionContext };
    onClose: (data: DraftEntityData) => void;
}

export function EditEntityComponent({
    onClose,
    context,
    mappings,
    conditions,
    ...props
}: EditEntityComponentProps): JSX.Element {
    const [data, onChange] = useState(props.data);
    const idEditor = useContext(PayloadEditorContext);
    const project = useCurrentProject();
    const entities = useAppSelector((state) => state.site.entities);
    const clientEntity = useClientEntity();
    const concepts = useContext(PayloadEditorConceptsContext);
    const dispatch = usePayloadEditorDispatch();

    useEffect(() => {
        dispatch(PayloadEditorActions.setDisabled(idEditor, true));
    }, []);

    const handleSave = useCallback(async () => {
        if (data.type !== props.data.type) return;

        const condition: Condition | null = await dispatch(
            calcCondition({
                idCondition: idEditor + '-DraftEntityEditor',
            }) as any
        ).unwrap();
        //Posible handle invalid conditions?
        dispatch(clearConditionEditor([idEditor + '-DraftEntityEditor']));

        const entityData = await produce(data, async (data) => {
            if (isMappableEntityData(data)) {
                const payload: Payload | null = await dispatch(
                    PayloadEditorActions.calcPayload({
                        idEditor: idEditor + '-DraftEntity',
                    }) as any
                ).unwrap();
                if (!payload?.draft) return;
                data.block = payload.draft.blocks[0];
                data.entityMap = payload.draft.entityMap;
            }
            if (condition) data.condition = condition;
            else if (data.type !== DraftEntityDataTypes.CONDITION_MET)
                data.condition = undefined;
        });
        onClose(entityData);
        dispatch(PayloadEditorActions.setDisabled(idEditor, false));
    }, [data]);

    const renderContent = (): JSX.Element => {
        switch (data.type) {
            case DraftEntityDataTypes.AGENTS:
                return (
                    <div className={styles.contentContainer}>
                        <PayloadEditor
                            idEditor={idEditor + '-DraftEntity'}
                            payload={{
                                draft: {
                                    blocks: [data.block],
                                    entityMap: data.entityMap,
                                },
                            }}
                            nested
                            media={false}
                            context={context}
                            mappings={{
                                ...mappings,
                                agent: true,
                            }}
                            multiline={false}
                            conditions={conditions}
                        />
                    </div>
                );
            case DraftEntityDataTypes.CLASSIFIER:
                return (
                    <div className={styles.contentContainer}>
                        {/* //TODO: Picker de raiz de clasificador */}
                    </div>
                );
            case DraftEntityDataTypes.DATE:
                return (
                    <div className={styles.contentContainer}>
                        {!context?.isPublic && (
                            <div className={styles.workingContainer}>
                                Calcular Fecha Hábil:
                                <Toggle
                                    size="small"
                                    checked={data.time.working}
                                    onChange={(working: boolean): void => {
                                        onChange(
                                            produce(data, (data) => {
                                                data.time.working = working;
                                            })
                                        );
                                    }}
                                />
                            </div>
                        )}
                        <div className={styles.daysContainer}>
                            <div className={styles.dayContainer}>
                                <RoundedTextField
                                    label="Días"
                                    onChange={(event): void =>
                                        onChange(
                                            produce(data, (data) => {
                                                data.time.days = parseInt(
                                                    event.target.value
                                                );
                                            })
                                        )
                                    }
                                    type="number"
                                    value={data.time.days ?? 0}
                                />
                            </div>
                            <div className={styles.dayContainer}>
                                <RoundedTextField
                                    label="Horas"
                                    onChange={(event): void =>
                                        onChange(
                                            produce(data, (data) => {
                                                data.time.hours = parseInt(
                                                    event.target.value
                                                );
                                            })
                                        )
                                    }
                                    type="number"
                                    value={data.time.hours ?? 0}
                                />
                            </div>
                            <div className={styles.dayContainer}>
                                <RoundedTextField
                                    label="Minutos"
                                    onChange={(event): void =>
                                        onChange(
                                            produce(data, (data) => {
                                                data.time.minutes = parseInt(
                                                    event.target.value
                                                );
                                            })
                                        )
                                    }
                                    type="number"
                                    value={data.time.minutes ?? 0}
                                />
                            </div>
                        </div>
                        <DateFormatPicker
                            value={data.format ?? 'Pp'}
                            onChange={(format): void =>
                                onChange(
                                    produce(data, (data) => {
                                        data.format = format;
                                    })
                                )
                            }
                        />
                    </div>
                );
            case DraftEntityDataTypes.TICKET: {
                switch (data.property) {
                    case TicketPropertyTypes.CLOSED_DATE:
                    case TicketPropertyTypes.CREATION_DATE:
                    case TicketPropertyTypes.RESOLUTION_DATE:
                        return (
                            <div className={styles.contentContainer}>
                                <DateFormatPicker
                                    value={data.format ?? 'Pp'}
                                    onChange={(format): void =>
                                        onChange(
                                            produce(data, (data) => {
                                                data.format = format;
                                            })
                                        )
                                    }
                                />
                            </div>
                        );
                    default:
                        return <></>;
                }
            }
            case DraftEntityDataTypes.CONCEPT: {
                const concept = concepts.find(
                    (concept) => concept.id === data.idConcept
                );
                if (!concept) return <></>;
                return (
                    <div className={styles.contentContainer}>
                        <PayloadEditor
                            idEditor={idEditor + '-DraftEntity'}
                            payload={{
                                draft: {
                                    blocks: [data.block],
                                    entityMap: data.entityMap,
                                },
                            }}
                            nested
                            media={false}
                            context={context}
                            mappings={{
                                ...mappings,
                                concepts: [
                                    ...(mappings?.concepts ?? []),
                                    concept,
                                ],
                            }}
                            multiline={false}
                            conditions={conditions}
                        />
                    </div>
                );
            }
            case DraftEntityDataTypes.NESTED:
                return (
                    <div className={styles.contentContainer}>
                        <PayloadEditor
                            idEditor={idEditor + '-DraftEntity'}
                            payload={{
                                draft: {
                                    blocks: [data.block],
                                    entityMap: data.entityMap,
                                },
                            }}
                            nested
                            media={false}
                            context={context}
                            mappings={mappings}
                            multiline={false}
                            conditions={conditions}
                        />
                    </div>
                );
            case DraftEntityDataTypes.ENTITYVALUES: {
                if (!isMultipleEntityValueEntityData(data, entities, project))
                    return <></>;
                return (
                    <div className={styles.contentContainer}>
                        <PayloadEditor
                            idEditor={idEditor + '-DraftEntity'}
                            payload={{
                                draft: {
                                    blocks: [data.block],
                                    entityMap: data.entityMap,
                                },
                            }}
                            nested
                            media={false}
                            context={context}
                            mappings={{
                                ...mappings,
                                entities: [
                                    ...(mappings?.entities ?? []),
                                    data.idEntity,
                                ],
                            }}
                            multiline={false}
                            conditions={conditions}
                        />
                    </div>
                );
            }
            case DraftEntityDataTypes.CLIENT_RELATIONSHIP: {
                if (
                    !isMultipleClientRelationshipEntityData(
                        data,
                        entities,
                        clientEntity
                    )
                )
                    return <></>;
                return (
                    <div className={styles.contentContainer}>
                        <PayloadEditor
                            idEditor={idEditor + '-DraftEntity'}
                            payload={{
                                draft: {
                                    blocks: [data.block],
                                    entityMap: data.entityMap,
                                },
                            }}
                            nested
                            media={false}
                            context={context}
                            mappings={{
                                ...mappings,
                                entities: [
                                    ...(mappings?.entities ?? []),
                                    data.idEntity,
                                ],
                            }}
                            multiline={false}
                            conditions={conditions}
                        />
                    </div>
                );
            }
            case DraftEntityDataTypes.INTEGRATION:
            case DraftEntityDataTypes.CLIENT_INTEGRATION:
            case DraftEntityDataMappingTypes.ENTITYVALUE_INTEGRATION_MAPPING: {
                return (
                    <div className={styles.contentContainer}>
                        <IntegrationErkValuesPicker
                            context={context}
                            values={data.values}
                            idIntegration={data.idIntegration}
                            handleUpdate={(values): void =>
                                onChange({ ...data, values })
                            }
                        />
                    </div>
                );
            }
            default:
                return <></>;
        }
    };

    return (
        <Dialog open={true} onClose={handleSave} maxWidth={'80vw'}>
            <EntityEditorContainer
                data={data}
                onChange={onChange}
                conditions={conditions}
            >
                {renderContent()}
            </EntityEditorContainer>
        </Dialog>
    );
}

interface EntityEditorProps {
    data: DraftEntityData;
    context?: DraftContext;
    mappings?: DraftMappings;
    conditions: { types: ConditionTypes[]; context?: ConditionContext };
}

function EntityEditor({
    data,
    context,
    mappings,
    conditions,
}: EntityEditorProps): JSX.Element {
    const idEditor = useContext(PayloadEditorContext);
    const dispatch = usePayloadEditorDispatch();
    const idGenericEditor = useContext(IdGenericEditorContext);
    const concepts = useContext(PayloadEditorConceptsContext);

    const handleSave = useCallback(
        async (entityData: DraftEntityData) => {
            dispatch(
                PayloadEditorActions.saveEditing({
                    idEditor,
                    idGenericEditor,
                    entityData,
                    concepts,
                })
            );
        },
        [idEditor]
    );

    return (
        <EditEntityComponent
            data={data}
            context={context}
            mappings={mappings}
            onClose={handleSave}
            conditions={conditions}
        />
    );
}

export default EntityEditor;
