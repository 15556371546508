import { AnyAction } from '@reduxjs/toolkit';
import { Entity, EntityValue } from '../../@Types/@Types';
import * as Actions from './EntityValuesActions';
import ScrollTypes from '../../constants/ScrollTypes';
const defaultOrderBy = 'latest';

export interface EntityValuesPageState {
    /** The id of the current entity */
    entity: Entity | undefined;
    /** The currently displayed EntityValues */
    elements: EntityValue[];
    /** The currently active filters */
    filters: EntityValueFilters;
    /** The  way the elements are currently ordered. undefined if search is active*/
    orderBy: string | undefined; //TODO definir orderby
    /** The current search, undefined if none */
    search: string | undefined;
    /** the index of the currently selectedElement */
    selectedElement: number | undefined;
    pageUp: number;
    pageDown: number;
    /** Active if no more pages are available */
    lastPage: boolean;
    /** If loader is active */
    loading: boolean;
}
export interface EntityValueFilters {
    values: Record<string, any>;
    relationships: Record<string, EntityValue[]>;
}

export const defaultFilters = {
    values: {},
    relationships: {},
};

const initialState = {
    entity: undefined,
    elements: [],
    filters: defaultFilters,
    orderBy: defaultOrderBy,
    search: undefined,
    selectedElement: undefined,
    pageUp: 1,
    pageDown: 1,
    lastPage: false,
    loading: true,
};

/**
 * Redux Reducer that handles EntityValuesActions Tiggers
 * @param state The current state
 * @param action the action that was triggered
 * @returns the new state
 */
const EntityValuesReducer = (
    state: EntityValuesPageState = initialState,
    action: AnyAction
): EntityValuesPageState => {
    if (Actions.reset.match(action)) {
        return {
            ...state,
            entity: action.payload.entity,
            elements: [],
            filters: action.payload.entity.defaultFilters
                ? {
                      ...defaultFilters,
                      values: action.payload.entity.defaultFilters,
                  }
                : defaultFilters,
            orderBy: defaultOrderBy,
            search: undefined,
            selectedElement: undefined,
            pageUp: 1,
            pageDown: 1,
            lastPage: false,
            loading: true,
        };
    } else if (Actions.resetSuccess.match(action)) {
        return {
            ...state,
            loading: false,
            elements: action.payload.elements,
            selectedElement: action.payload.selectedElement,
            pageUp: action.payload.page,
            pageDown: action.payload.page,
            lastPage: action.payload.lastPage,
            filters: action.payload.filters,
            orderBy: action.payload.orderBy,
            search: action.payload.search,
        };
    } else if (Actions.setLoading.match(action)) {
        return {
            ...state,
            loading: action.payload,
        };
    } else if (Actions.getSuccess.match(action)) {
        return {
            ...state,
            loading: false,
            selectedElement: action.payload.selectedElement,
            elements: action.payload.elements,
            lastPage: action.payload.lastPage,
        };
    } else if (Actions.pageScroll.match(action)) {
        if (action.payload === ScrollTypes.DOWN) {
            return { ...state, pageDown: state.pageDown + 1 };
        } else if (state.pageUp > 1) {
            return { ...state, pageUp: state.pageUp - 1 };
        }
    } else if (Actions.selectElement.match(action)) {
        return { ...state, selectedElement: action.payload };
    } else if (Actions.refreshSuccess.match(action)) {
        return {
            ...state,
            elements: state.elements.map((element) =>
                element._id === action.payload._id ? action.payload : element
            ),
        };
    } else if (Actions.search.match(action)) {
        return {
            ...state,
            elements: [],
            selectedElement: undefined,
            pageUp: 1,
            pageDown: 1,
            lastPage: false,
            loading: true,
            search: action.payload === '' ? undefined : action.payload,
            orderBy: action.payload === '' ? defaultOrderBy : undefined,
        };
    } else if (Actions.orderBy.match(action)) {
        return {
            ...state,
            search: undefined,
            orderBy: action.payload,
        };
    } else if (Actions.setFilters.match(action)) {
        return {
            ...state,
            elements: [],
            selectedElement: undefined,
            pageUp: 1,
            pageDown: 1,
            loading: true,
            filters: action.payload,
        };
    }
    return state;
};

export function entityValuesToQueryString(
    entity: Entity,
    filters: EntityValueFilters | undefined,
    orderBy?: string | undefined,
    search?: string | undefined,
    ignoreDefault: boolean = true
): string {
    const url = new URLSearchParams();
    if (filters) {
        const values = [];
        for (const [key, value] of Object.entries(filters.values)) {
            if (value !== undefined) {
                values.push(`${key}:${value}`);
            }
        }
        if (values.length > 0) url.append('values', values.join(';'));

        const relationships = [];
        for (const [idEntity, entityValues] of Object.entries(
            filters.relationships
        )) {
            if (entityValues && entityValues.length > 0) {
                relationships.push(
                    `${idEntity}:${entityValues
                        .map((entityValue) => entityValue?._id ?? entityValue)
                        .join(',')}`
                );
            }
        }
        if (relationships.length > 0)
            url.append('relationships', relationships.join(';'));
    }

    if (search) {
        url.set('search', encodeURIComponent(search));
    }
    if ((ignoreDefault && orderBy !== defaultOrderBy) || !ignoreDefault) {
        url.set('orderBy', orderBy ?? defaultOrderBy);
    }
    return url.toString();
}
export default EntityValuesReducer;
