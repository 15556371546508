import styles from './FileStep.module.css';
import { FileStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import { useDispatch } from 'react-redux';
import { updateStep } from '../../../../../../controllers/ConversationEditorController/ConversationEditorActions';
import {
    ApiKeyContext,
    BaseConversationPayloadConditionTypes,
} from '../../../ConversationEditor';
import PayloadEditor from '../../../../../../shared/TextEditor/PayloadEditor';
import { useContext, useMemo } from 'react';
import { TextEditorTypes } from '../../../../../../constants/TextEditorTypes';
import Toggle from '../../../../../../shared/Toggle/Toggle';
import RoundedClientPropertyPicker from '../../../../../../shared/@Pickers/RoundedClientPropertyPicker/RoundedClientPropertyPicker';
import EntityPropertyTypes from '../../../../../../constants/EntityPropertyTypes';
import React from 'react';
import { useAppSelector } from '../../../../../../hooks';

interface FileStepProps {
    step: FileStep;
}

function FileStepComponent({ step }: FileStepProps): JSX.Element {
    const dispatch = useDispatch();
    const apiKey = useContext(ApiKeyContext);

    const clientEntity = useAppSelector((state) => state.site.clientEntity);

    const fileProperties = useMemo(
        () =>
            Object.keys(clientEntity?.steps ?? {}).filter(
                (key) =>
                    clientEntity?.steps[key]?.type ===
                    EntityPropertyTypes.FILEUPLOAD
            ),
        [clientEntity?.steps]
    );

    return (
        <div className={styles.container}>
            <div className={styles.editorContainer}>
                <PayloadEditor
                    placeholder={'Mensaje'}
                    type={TextEditorTypes.WHATSAPP}
                    idEditor={`Conversation-${apiKey}-${step.id}`}
                    payload={{ draft: step.message }}
                    onChange={({ draft: message }): void => {
                        if (!message) return;
                        dispatch(updateStep({ ...step, message }));
                    }}
                    media={false}
                    context={{ client: true }}
                    conditions={{
                        types: BaseConversationPayloadConditionTypes,
                        context: {
                            conversation: {
                                idStep: step.id,
                            },
                        },
                    }}
                    minHeight={144}
                    maxHeight={400}
                    required
                    maxLength={4096}
                />
            </div>
            {fileProperties.length > 0 && (
                <div className={styles.clientContainer}>
                    <div className={styles.toggleLabelContainer}>
                        <div className={styles.clientLbl}>
                            Información del Cliente:
                        </div>
                        <div className={styles.toggleContainer}>
                            <Toggle
                                checked={step.idClientProperty !== undefined}
                                onChange={(checked: boolean): void => {
                                    const tempStep = checked
                                        ? {
                                              ...step,
                                              idClientProperty:
                                                  fileProperties[0],
                                              recurring: checked,
                                          }
                                        : {
                                              ...step,
                                              idClientProperty: undefined,
                                              recurring: undefined,
                                          };
                                    dispatch(updateStep(tempStep));
                                }}
                            />
                        </div>
                    </div>
                    {step.idClientProperty !== undefined && (
                        <React.Fragment>
                            <div className={styles.clientInfoContainer}>
                                <div className={styles.clientInfoLabel}>
                                    Atributo:{' '}
                                </div>
                                <div
                                    className={
                                        styles.clientInfoSelectorContainer
                                    }
                                >
                                    <RoundedClientPropertyPicker
                                        height="31px"
                                        fullWidth
                                        label={''}
                                        MenuProps={{ disablePortal: true }}
                                        value={step.idClientProperty}
                                        propertyTypes={[
                                            EntityPropertyTypes.FILEUPLOAD,
                                        ]}
                                        handleUpdate={(
                                            idClientProperty
                                        ): void => {
                                            dispatch(
                                                updateStep({
                                                    ...step,
                                                    idClientProperty,
                                                })
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={styles.recurringLabelContainer}>
                                <div className={styles.clientLbl}>
                                    Preguntar Siempre:
                                </div>
                                <div className={styles.toggleContainer}>
                                    <Toggle
                                        checked={step.recurring === true}
                                        onChange={(recurring): void => {
                                            dispatch(
                                                updateStep({
                                                    ...step,
                                                    recurring,
                                                })
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            )}
            {/* <div className={styles.toggleLabelContainer}>
                Mostrar en la Traza:
                <div className={styles.toggleContainer}>
                    <Toggle
                        checked={step.showInTrace}
                        onChange={(checked: boolean): void => {
                            dispatch(
                                updateStep({ ...step, showInTrace: checked })
                            );
                        }}
                    />
                </div>
            </div> */}
        </div>
    );
}

export default FileStepComponent;
