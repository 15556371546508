import styles from './Classify.module.css';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { renderClassifierIcon, SearchClassifier } from '../../ClassifyMenu';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';

interface ClassifyProps {
    /** The classifier to display */
    element: SearchClassifier;
    /** Function to call when the component is clicked on  */
    handleClick: Function;
    /** Function to call when the viewMoreBtn of the classifier is clicked on */
    handleViewMore: Function;
    /** If the classifier is the last one on the list */
    last: boolean;
    /** The currently available classifiers to display */
    classifiers: Record<string, SearchClassifier>;
    /** The id of the value of the classifer */
    idValue: string | null;
    /** If the user is currently searching something */
    searching: boolean;
}
/**
 * Classifiable component of the classifying option, this component can be selected to classify
 * the classifier with its value or it can be clicked on to show its children
 */
function ClassifyComponent({
    handleClick,
    element,
    last,
    handleViewMore,
    classifiers,
    idValue,
    searching,
}: ClassifyProps): JSX.Element {
    /** Find if the classifier has any children */
    const hasChildren = element.children.length > 0;
    /** Check if the classifier is parent of the classified value */
    const elementIsChild =
        hasChildren &&
        idValue &&
        classifiers[idValue]?.path?.includes(element._id);

    /** Function that renders text with a highlight if it has a search value in it */
    const renderText = (text: string): any => {
        if (text) {
            var parts: any = text.split(/<SEARCH:(.*?)>/gi);
            for (var i = 1; i < parts.length; i += 2) {
                parts[i] = (
                    <span className={styles.search} key={i}>
                        {parts[i]}
                    </span>
                );
            }
            return parts;
        }
    };
    return (
        <div
            className={styles.container}
            onClick={(): void => {
                if (hasChildren) {
                    handleViewMore();
                }
            }}
            style={{
                borderBottom: last ? 'none' : '1px solid var(--light-grey)',
            }}
        >
            <div className={styles.left}>
                <div className={styles.top}>
                    <div
                        className={styles.checkIcon}
                        onClick={(e): void => {
                            e.stopPropagation();
                            handleClick();
                        }}
                    >
                        {renderClassifierIcon(element)}
                    </div>
                    <span className={styles.rootLbl}>
                        {renderText(element?.name)}
                    </span>
                </div>
                {element?.description.trim() !== '' && (
                    <div className={styles.bottom}>
                        {renderText(element?.description)}
                    </div>
                )}
            </div>
            {hasChildren && (
                <div
                    className={styles.nextBtn}
                    onClick={(e): void => {
                        e.stopPropagation();
                        handleViewMore(false);
                    }}
                >
                    <ExpandMoreRoundedIcon fontSize="inherit" />
                    {elementIsChild && (
                        <div className={styles.nextUnderLine}>
                            <RemoveRoundedIcon fontSize="inherit" />
                        </div>
                    )}
                    {searching && element.hasSearchInChild && (
                        <div className={styles.searchSymbol}>
                            <SearchRoundedIcon fontSize="inherit" />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default ClassifyComponent;
