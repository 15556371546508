import React, { useMemo, useState } from 'react';
import Filters, {
    AllowedFilters,
    AnalyticFilters,
    BaseFilters,
    parseAnalyticFilters,
} from '../Filters/Filters';
import styles from './FrameWithFilters.module.css';
import { Visualization } from '../../../@Types/Metabase';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import ProdesaFilters from '../Filters/ProdesaFilters';
import SubTabs from './SubTabs/SubTabs';

interface FrameWithFiltersProps {
    /** Visualization that is going to be displayed */
    visualization: Visualization;
    /** URL that is going to be displayed */
    urlLink: string;
    /** Function called when the iframe has loaded */
    urlLinks?: string[];
    setIframeLoaded: Function;
    /** if app is currently mobile */
    mobile: boolean;
}
function FrameWithFilters({
    mobile,
    visualization,
    urlLink,
    urlLinks,
    setIframeLoaded,
}: FrameWithFiltersProps): JSX.Element {
    const siteInfo = useSelector((state: RootState) => state.site);
    const [filters, setFilters] = useState<AnalyticFilters>({
        values: {},
        entities: {},
        idOrganization:
            visualization.description?.includes('idorganization') ||
            visualization.description?.includes('idOrganization')
                ? siteInfo.organization?.idOrganization!
                : undefined,
    });
    const [urlLinkR, setUrlLinkR] = useState(urlLinks?.[0] ?? urlLink);

    const allowedFilters: AllowedFilters = useMemo(() => {
        const values = [];
        const entities = [];
        const filters: AllowedFilters = {};
        const descriptionFilters =
            visualization.description
                ?.replace('--filters--', '')
                ?.split('--') ?? [];
        for (const f of descriptionFilters) {
            const filter = f.replace(/(\r\n|\n|\r)/gm, '');
            if (Object.values(BaseFilters).includes(filter as BaseFilters)) {
                filters[filter as BaseFilters] = true;
            } else if (filter.startsWith('ticket.')) {
                const path = filter.split('.');
                if (path[1] === 'values') {
                    values.push(path[2]);
                }
            } else if (filter.startsWith('entities.')) {
                const path = filter.split('.');
                entities.push({
                    idEntity: path[1],
                    multiple: path[2].trim() === 'true',
                });
            }
        }
        if (values.length > 0) filters.values = values;
        if (entities.length > 0) filters.entities = entities;
        return filters;
    }, [visualization]);

    const hiddenFilters: string = useMemo(() => {
        const filters =
            visualization.description
                ?.replace('--filters--', '')
                ?.split('--') ?? [];
        const hidden = [...filters, ...Object.values(BaseFilters)]
            .map((filter) => {
                switch (filter) {
                    case BaseFilters.dates:
                        return 'startDate,endDate';
                    case BaseFilters.dates2:
                        return 'startDate2,endDate2';
                    case BaseFilters.companies:
                        return 'idCompanies';
                    case BaseFilters.agents:
                        return 'idAgents';
                    case BaseFilters.agents2:
                        return 'idAgents2';
                    default:
                        return filter;
                }
            })
            .join(',');
        return hidden.toLowerCase() + ',' + hidden;
    }, [visualization]);

    const parseFiltersFunction = (url: string): string => {
        let originalUrl = parseAnalyticFilters(
            siteInfo,
            filters,
            allowedFilters
        );
        if (url.includes('dashboard')) {
            originalUrl = originalUrl.replace(
                /firstClassifiers/g,
                'firstclassifiers'
            );
            originalUrl = originalUrl.replace(
                /secondClassifiers/g,
                'secondclassifiers'
            );
            originalUrl = originalUrl.replace(/temporality/g, 'temporality');
            originalUrl = originalUrl.replace(/startDate/g, 'startdate');
            originalUrl = originalUrl.replace(/endDate/g, 'enddate');
            originalUrl = originalUrl.replace(/idCompanies/g, 'idcompanies');
            originalUrl = originalUrl.replace(
                /idOrganization/g,
                'idorganization'
            );
            originalUrl = originalUrl.replace(/idAgents/g, 'idagents');
            originalUrl = originalUrl.replace(/macroProject/g, 'macroproject');
            originalUrl = originalUrl.replace(
                /lvpEstadoInmueble/g,
                'lvpestadoinmueble'
            );
            originalUrl = originalUrl.replace(
                /lvpEstadoPosventa/g,
                'lvpestadoposventa'
            );
            originalUrl = originalUrl.replace(
                /ariasOrigenPosventa/g,
                'ariasorigenposventa'
            );
            originalUrl = originalUrl.replace(
                /estadoProcesoPosventa/g,
                'estadoprocesoposventa'
            );
            originalUrl = originalUrl.replace(
                /responseStates/g,
                'responsestates'
            );
            originalUrl = originalUrl.replace(
                /prodesaRegional/g,
                'prodesaregional'
            );
            originalUrl = originalUrl.replace(/onVacations/g, 'onvacations');
            originalUrl = originalUrl.replace(
                /classifiersMultiple/g,
                'classifiersmultiple'
            );
        }
        return (
            originalUrl +
            '#bordered=false&titled=false&hide_parameters=' +
            hiddenFilters
        );
    };

    if (urlLinkR) {
        return (
            <React.Fragment>
                {siteInfo.organization?.idOrganization === 'prodesa' &&
                visualization.description?.includes('--prodesaRegional') ? (
                    <ProdesaFilters
                        mobile={mobile}
                        allowedFilters={allowedFilters}
                        filters={filters}
                        setFilters={setFilters}
                    />
                ) : (
                    <Filters
                        mobile={mobile}
                        allowedFilters={allowedFilters}
                        filters={filters}
                        setFilters={setFilters}
                    />
                )}
                {visualization.type === 'CompoundedDashboard' && (
                    <SubTabs
                        visualization={visualization}
                        setUrlLink={setUrlLinkR}
                        urlLinks={urlLinks as any}
                        setLoading={setIframeLoaded}
                    />
                )}
                <div className={styles.iframeContainer} key={urlLinkR}>
                    <iframe
                        src={
                            urlLinkR.split('#')[0] +
                            parseFiltersFunction(urlLinkR)
                        }
                        width="100%"
                        frameBorder="0"
                        className={
                            mobile
                                ? styles.mobileIframeStyles
                                : styles.iframeStyles
                        }
                        onLoad={(): void => {
                            setIframeLoaded(true);
                        }}
                    />
                </div>
            </React.Fragment>
        );
    } else {
        return <div></div>;
    }
}

export default FrameWithFilters;
