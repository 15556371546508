import styles from './UpdateDialog.module.css';
import { useEffect, useState } from 'react';
import { User } from '../../@Types/User';
import Dialog from '../Dialog/Dialog';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import { convertFromRaw, EditorState } from 'draft-js';

interface UpdateDialogProps {
    user: User | null;
}
function UpdateDialog({ user }: UpdateDialogProps): JSX.Element {
    const [showDialog, setShowDialog] = useState(false);
    useEffect(() => {
        if (user && user.lastLogin) {
            //Mes - 1
            const updateDate = new Date('2024-12-30T17:00:00.000Z');

            const lastLogin = new Date(user.lastLogin);
            const displayUpdate =
                updateDate > lastLogin &&
                localStorage.getItem('updateViewed') === null &&
                user?.permissions?.organization?.PERMISSIONS === true;
            if (displayUpdate) {
                setShowDialog(true);
                localStorage.setItem('updateViewed', 'TRUE');
            }
        }
    }, [user]);

    return (
        <Dialog
            onClose={(): void => {
                setShowDialog(false);
            }}
            maxWidth={'90vw'}
            open={showDialog}
        >
            <UpdateComponent
                onClose={(): void => {
                    (): void => {
                        setShowDialog(false);
                    };
                }}
            />
        </Dialog>
    );
}

export default UpdateDialog;

const draftContent = {
    blocks: [
        {
            key: '5jmfa',
            text: 'Prueba',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        },
        {
            key: '4t7b1',
            text: ' ',
            type: 'atomic',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [{ offset: 0, length: 1, key: 0 }],
            data: {},
        },
    ],
    entityMap: {
        '0': {
            type: 'IMAGE',
            mutability: 'MUTABLE',
            data: {
                ratio: 1.6339869281045751,
                height: 153,
                width: 250,
                imageKey: '123',
            },
        },
    },
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const images = {
    '123': {
        url: 'https://images-na.ssl-images-amazon.com/images/S/pv-target-images/d7c0645cb6208f859d9d79f374738c76ac30dc901895a543539ae9ecee5a0be8._UR1920,1080_RI_SX356_FMwebp_.jpg',
    },
};

function UpdateComponent({ onClose }: { onClose: Function }): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const editorState = EditorState.createWithContent(
        convertFromRaw(draftContent as any)
    );
    return (
        <div className={styles.container}>
            <div
                className={styles.exitBtn}
                onClick={(): void => {
                    onClose();
                }}
            >
                <ClearRoundedIcon
                    style={{ fill: 'black' }}
                    fontSize="inherit"
                />
            </div>

            <img
                src={'/media/Update.jpeg'}
                alt={'Actualización'}
                style={{
                    cursor: 'pointer',
                    height: 'auto',
                    width: 'auto',
                    maxWidth: '80vw',
                    maxHeight: '90vh',
                }}
                onClick={(): void => {
                    var win = window.open(
                        'https://capta.forms.capta.co/n34iIkD5vxZtF7EMVqlXp',
                        '_blank'
                    );
                    win?.focus();
                }}
            />

            {/* 
                 <div className={styles.title}>Informacion de Actualización</div>
            <div className={styles.helperText}>05/01/2022</div>
            <div className={styles.draftContainer}>
                <DraftRenderer
                    editorState={editorState}
                    images={images as any}
                />
            </div> */}
        </div>
    );
}
