import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import TitleIcon from '@material-ui/icons/Title';
import SubjectIcon from '@material-ui/icons/Subject';
import { MenuItem } from '@material-ui/core';
import RoundedSelect, {
    RoundedSelectProps,
} from '../../RoundedSelect/RoundedSelect';
import { useMemo } from 'react';
import EntityProperties from '../../../constants/EntityPropertyTypes';
import styles from './RoundedEntityPropertyPicker.module.css';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { EntityProperty } from '../../../@Types/EntityTypes/EntityProperty';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import EntityIcon from '../../SmartIcons/EntityIcon';
import EntityPropertyTypes from '../../../constants/EntityPropertyTypes';
import ScheduleRounded from '@material-ui/icons/ScheduleRounded';
import IntegrationTypes from '../../../constants/IntegrationTypes';
import { IntegrationsApi } from '../../../controllers/IntegratrionsController/IntegrationsService';
import { useAppSelector, useIdProject } from '../../../hooks';
import ApiIcon from '../../../Icons/ApiIcon';
import SmartIcon from '../../SmartIcons/SmartIcon';
import { Integration } from '../../../@Types/Integration';
import FileIcon from '../../../Icons/FileIcon';

interface RoundedEntityPropertyPickerProps
    extends Omit<RoundedSelectProps, 'handleUpdate' | 'value'> {
    /** The entity to select the property from */
    idEntity?: string;
    steps?: Record<string, EntityProperty>;
    /** Currently selected entityProperty or integration */
    value: string | undefined;
    properties?: EntityProperty[];
    integrations?: Integration[];
    /** The possible properties to select */
    elementsToOmit?: string[];
    /** The posible properties types to select */
    propertyTypes?: EntityPropertyTypes[];
    /** The posible integrations to select */
    integrationTypes?: IntegrationTypes[];
    /** function called when value changes */
    handleUpdate: (value: EntityProperty | Integration | undefined) => void;
    /** If should should public properties only */
    publicProperties?: boolean;
}

const emptySteps = {};

function RoundedEntityPropertyPicker({
    value,
    idEntity,
    handleUpdate,
    propertyTypes,
    elementsToOmit = [],
    integrationTypes = [],
    publicProperties = false,
    ...others
}: RoundedEntityPropertyPickerProps): JSX.Element {
    const idProject = useIdProject();

    const { data: entityIntegrations } =
        IntegrationsApi.useLoadIntegrationsQuery({
            idEntity,
            idProject,
        });

    const entitySteps = useAppSelector(
        (state) => state.site.entities[idEntity ?? '']?.steps ?? emptySteps
    );

    const steps = others.steps ?? entitySteps;
    const properties = useMemo(
        () =>
            Object.keys(steps)
                .filter(
                    (idProperty) =>
                        idProperty === value ||
                        (!elementsToOmit.includes(idProperty) &&
                            (!propertyTypes ||
                                propertyTypes.includes(
                                    steps[idProperty]?.type
                                )) &&
                            (!publicProperties ||
                                (steps[idProperty] as any).public))
                )
                .map((idProperty) => steps[idProperty]),
        [others.properties, steps, elementsToOmit, publicProperties]
    );
    const integrations = useMemo(
        () =>
            entityIntegrations?.filter(
                (integration) =>
                    integration._id === value ||
                    (integration &&
                        !elementsToOmit.includes(integration._id) &&
                        integrationTypes.includes(integration.type))
            ) as Integration[],
        [others.integrations, entityIntegrations, integrationTypes]
    );

    return (
        <RoundedSelect
            value={value && !integrations && !steps[value] ? undefined : value}
            paddingLeft={0}
            containerMargin="0px"
            handleUpdate={(event): void => {
                const idProperty = event.target.value;
                handleUpdate(
                    steps[idProperty] ??
                        entityIntegrations?.find(
                            (integration) => integration._id === idProperty
                        )
                );
            }}
            {...others}
        >
            {(others.properties ?? properties).map((property) => (
                <MenuItem
                    key={property.id}
                    value={property.id}
                    style={{
                        paddingLeft: 0,
                    }}
                >
                    <PropertyComponent property={property} />
                </MenuItem>
            ))}
            {!publicProperties &&
                (others.integrations ?? integrations)?.map((integration) => (
                    <MenuItem
                        key={integration._id}
                        value={integration._id}
                        style={{
                            paddingLeft: 0,
                        }}
                    >
                        <div className={styles.property}>
                            <div className={styles.icon}>
                                {integration.icon ? (
                                    <SmartIcon icon={integration.icon} />
                                ) : (
                                    <ApiIcon />
                                )}
                            </div>
                            {integration.name}
                        </div>
                    </MenuItem>
                ))}
        </RoundedSelect>
    );
}
export default RoundedEntityPropertyPicker;

export function PropertyComponent({
    property,
}: {
    property: EntityProperty;
}): JSX.Element {
    switch (property.type) {
        case EntityProperties.NAME: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.nameLbl}>
                            <TitleIcon fontSize="inherit" />
                        </div>
                    </div>
                    {property.label}
                </div>
            );
        }
        case EntityProperties.TITLE: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.nameLbl}>
                            <TitleIcon fontSize="inherit" />
                        </div>
                    </div>
                    {property.title}
                </div>
            );
        }
        case EntityProperties.FILEUPLOAD: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.nameLbl}>
                            <FileIcon fill="var(--secondary)" />
                        </div>
                    </div>
                    {property.label}
                </div>
            );
        }
        case EntityProperties.TEXTINPUT: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.textInputContainer}>A..</div>
                    </div>
                    {property.label}
                </div>
            );
        }
        case EntityProperties.SELECTOR: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.selectInputContainer}>
                            <ArrowDropDownIcon fontSize="inherit" />
                        </div>
                    </div>
                    {property.label}
                </div>
            );
        }
        case EntityProperties.DATEPICKER: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.dateInputContainer}>
                            <CalendarTodayRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                    {property.label}
                </div>
            );
        }
        case EntityProperties.TIMEPICKER: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.dateInputContainer}>
                            <ScheduleRounded fontSize="inherit" />
                        </div>
                    </div>
                    {property.label}
                </div>
            );
        }
        case EntityProperties.CLIENTPICKER:
        case EntityProperties.AGENTPICKER: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.agentInputContainer}>
                            <PersonRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                    {property.label}
                </div>
            );
        }
        case EntityProperties.TEXTAREA: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.textAreaContainer}>
                            <SubjectIcon fontSize="inherit" />
                        </div>
                    </div>
                    {property.label}
                </div>
            );
        }
        case EntityProperties.CHECKBOX: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.checkBoxIcon}>
                            <CheckBoxRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                    {property.label}
                </div>
            );
        }
        case EntityProperties.ENTITY_RELATIONSHIP: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.entityIcon}>
                            <EntityIcon idEntity={property.idEntity} />
                        </div>
                    </div>
                    {property.label}
                </div>
            );
        }
        default:
            return <div></div>;
    }
}
