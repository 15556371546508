import React from 'react';
import styles from './Condition.module.css';
import { ConditionProps } from '../Condition';
import { AICondition } from '../../../@Types/ConditionTypes/AICondition';
import RoundedTextField from '../../RoundedTextField/RoundedTextField';
import { useAppSelector } from '../../../hooks';
import { checkAdmin } from '../../../utils/PermissionsFunctions';

interface AIConditionProps extends ConditionProps<AICondition> {}

function AIConditionComponent({
    condition,
    hoverDelete,
    handleUpdate,
}: AIConditionProps): JSX.Element {
    const user = useAppSelector((state) => state.site.user);

    return (
        <div className={styles.valueContainer}>
            <RoundedTextField
                value={condition.condition}
                label={'Condición'}
                height="40px"
                fullWidth
                disabled={
                    !(
                        checkAdmin(user) ||
                        user?.email === 'direcciongeneral@apoyosfinancieros.com'
                    )
                }
                error={hoverDelete}
                onChange={(event): void => {
                    handleUpdate({
                        ...condition,
                        condition: event.target.value,
                    });
                }}
            />
        </div>
    );
}
export default AIConditionComponent;
