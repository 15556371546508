import styles from './Classifying.module.css';
import React from 'react';
import {
    ClassifierObj,
    newClassifier,
    SearchClassifier,
} from '../ClassifyMenu';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import Classify from './Classify/Classify';
import { useSelector } from 'react-redux';
import Parent from './Parent/Parent';
import { RootState } from '../../../../../../utils/_store';
interface ClassifyingProps {
    /** The classifier currently being classified */
    classifyingObj: ClassifierObj;
    /** Function called to change the object being classified */
    setClassifyingObj: Function;
    /** Function called to update the value of the change in the parent's change dictionary */
    change: Function;
    /** The currently available roots and classifiers to display */
    info: { roots: string[]; classifiers: Record<string, SearchClassifier> };
    /** If the user is currently searching */
    searching: boolean;
    /** the value of current search */
    search: string | undefined;
    /** if roots are allowed to classify */
    allowRoots?: boolean;
    /** noBorderTop */
    noBorderTop?: boolean;
}
/**
 * Component that displays the classifying action, allowing the user to classify
 *  a classifier with a diferent value
 */
function Classifying({
    classifyingObj,
    setClassifyingObj,
    change,
    info,
    searching,
    noBorderTop = false,
    search,
    allowRoots,
}: ClassifyingProps): JSX.Element {
    const siteInfo = useSelector((state: RootState) => state.site);
    let root = info.classifiers[classifyingObj.idRoot];
    /** If the root is not found in the filtered elements search it in the sites classifiers */
    if (root === undefined) {
        root = newClassifier(siteInfo.classifiers[classifyingObj.idRoot]);
    }

    /** Function that renders the path(parents) of the value currently being classified*/
    const renderCurrentPath = (): JSX.Element | void => {
        if (classifyingObj.current) {
            let element = info.classifiers[classifyingObj.current];
            if (!element) {
                element = newClassifier(
                    siteInfo.classifiers[classifyingObj.current]
                );
                if (search) {
                    const regex = new RegExp(search, 'gi');
                    if (regex.test(element.name)) {
                        element.name = element.name.replace(
                            regex,
                            (match) => `<SEARCH:${match}>`
                        );
                    }
                }
            }
            const elements = [];
            if (element.path) {
                for (let i = 1; i < element.path.length; i++) {
                    const id = element.path[i];
                    let parentObj = info.classifiers[id];
                    if (!parentObj) {
                        parentObj = newClassifier(siteInfo.classifiers[id]);
                        if (search) {
                            const regex = new RegExp(search, 'gi');
                            if (regex.test(parentObj.name)) {
                                parentObj.name = parentObj.name.replace(
                                    regex,
                                    (match) => `<SEARCH:${match}>`
                                );
                            }
                        }
                    }
                    elements.push(
                        <Parent
                            classifier={parentObj}
                            key={id}
                            handleClick={(): void => {
                                const father = element.path?.[i - 1];
                                setClassifyingObj({
                                    ...classifyingObj,
                                    idValue: id,
                                    current: father,
                                });
                            }}
                        />
                    );
                }
                elements.push(
                    <Parent
                        classifier={element}
                        key={element._id}
                        handleClick={(): void => {
                            const father =
                                element.path?.[element.path.length - 1];
                            setClassifyingObj({
                                ...classifyingObj,
                                idValue: element._id,
                                current: father,
                            });
                        }}
                    />
                );
            }
            return <React.Fragment>{elements}</React.Fragment>;
        }
    };

    /**
     * Function that renders the classifiers available to classify in the currently displayed path.
     */
    const renderCurrentClassifiers = (): JSX.Element => {
        // solo clasifica
        const classifiers = info.classifiers;
        let elements = classifiers[classifyingObj.current]?.children?.map(
            (idChild) => classifiers[idChild]
        );
        if (search) {
            elements = elements?.filter(
                (element) => element.hasSearch || element.hasSearchInChild
            );
        }
        if (elements.length === 0) {
            return (
                <div className={styles.emptyContainer}>
                    No hay clasificadores
                </div>
            );
        }
        return (
            <React.Fragment>
                {elements.map(
                    (element, index): JSX.Element => (
                        <Classify
                            searching={searching}
                            idValue={element._id}
                            classifiers={info.classifiers}
                            key={index}
                            last={index === elements.length - 1}
                            element={element}
                            handleClick={(): void => {
                                change(
                                    {
                                        idRoot: classifyingObj.idRoot,
                                        idValue: element._id,
                                    },
                                    true
                                );
                            }}
                            handleViewMore={(): void => {
                                setClassifyingObj({
                                    ...classifyingObj,
                                    current: element._id,
                                    idValue: undefined,
                                });
                            }}
                        />
                    )
                )}
            </React.Fragment>
        );
    };

    /** Function that renders the highlighted span in a searchterm */
    const renderText = (text: string): any => {
        if (text) {
            var parts: any = text.split(/<SEARCH:(.*?)>/gi);
            for (var i = 1; i < parts.length; i += 2) {
                parts[i] = (
                    <span className={styles.search} key={i}>
                        {parts[i]}
                    </span>
                );
            }
            return parts;
        }
    };

    return (
        <React.Fragment>
            <div
                className={styles.title}
                style={{
                    borderBottom:
                        classifyingObj.current !== classifyingObj.idRoot
                            ? '1px solid var(--greyBtn)'
                            : '1px solid var(--light-grey)',

                    borderTop: noBorderTop
                        ? 'none'
                        : '1px solid var(--light-grey)',
                }}
            >
                <div
                    className={styles.backBtn}
                    onClick={(): void => {
                        setClassifyingObj(undefined);
                    }}
                >
                    <ArrowBackIosRoundedIcon fontSize="inherit" />
                </div>
                <div className={styles.titleContainer}>
                    <div
                        className={styles.colorIcon}
                        style={{ backgroundColor: root.color?.background }}
                    ></div>
                    <label className={styles.nameLbl + ' noselect'}>
                        <span
                            className={styles.rootLbl}
                            style={{
                                cursor: allowRoots ? 'pointer' : 'default',
                            }}
                            onClick={(e): void => {
                                if (allowRoots) {
                                    setClassifyingObj(undefined);
                                    e.stopPropagation();
                                }
                            }}
                        >
                            {renderText(root.name)}
                        </span>
                    </label>
                </div>
            </div>
            {renderCurrentPath()}
            <div className={styles.container}>{renderCurrentClassifiers()}</div>
        </React.Fragment>
    );
}

export default Classifying;
