import styles from './ClassifyMenu.module.css';
import { useState, useEffect, useRef } from 'react';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Classifiers from './Classifiers/Classifiers';
import Classifying from './Classifying/Classifying';
import { useSelector } from 'react-redux';
import { TicketClassifier } from '../../../../../@Types/TicketTypes/Ticket';
import { RootState } from '../../../../../utils/_store';
import { Classifier } from '../../../../../@Types/Classifier';
import Search from '../../../../../shared/Search/Search';
import Dialog from '../../../../../shared/Dialog/Dialog';
import Toggle from '../../../../../shared/Toggle/Toggle';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';

/** Helper interface to optimize the render when a user is searching something */
export interface SearchClassifier extends SelectedClassifier {
    /** If the classifier has a child that has the term being searched */
    hasSearchInChild?: boolean;
    /** If the classifier has the term being serched */
    hasSearch?: boolean;
}

export interface SelectedClassifier extends Classifier {
    /** Number of childrens */
    countChildren: number;
    /** Number of childrens full selected */
    countFullSelected: number;
    /** Number of childrens full excluded */
    countFullExcluded: number;
    /** Number of childrens full empty */
    countFullEmpty: number;
    /** Number of childrens selected with empty childrens in linage (lineageState.fragment) */
    countFragmentSelected: number;
    /** Number of childrens excluded with empty childrens or selected childrens in linage (lineageState.fragment) */
    countFragmentExcluded: number;
    /** Number of childrens empty with excluded childrens or selected childrens in linage (lineageState.fragment) */
    countFragmentEmpty: number;
    /** the current state of the classifier node */
    state: ClassifierState;
    /** if the classifier has the same state in all its children */
    legacySameState: boolean;
    /** if add the path full */
    pathFull: boolean;
}

export enum ClassifierState {
    selected = 'selected',
    excluded = 'excluded',
    empty = 'empty',
}

/**
 * Object that stores the values currently being classified
 */
export interface ClassifierObj {
    /** root of the classifier currently bieng classified */
    idRoot: string;
    /** parent of the currently displayed classifiers */
    current: string;
}

export type classifiersStateData = Record<
    string,
    {
        idValuesSelected: string[];
        idPathSelected: string[];
        idValuesExcluded: string[];
        idPathExcluded: string[];
    }
>;

interface ClassifiersMenuProps {
    /** Element the menu is anchored to (classifiers button in the sidebar) */
    anchorRef: any;
    /** Function called when the menu closes */
    handleClose: Function;
    /** Function called when classifiers change */
    handleChange: Function;
    /** Functino called when the parent toggle changes */
    handleParentToggleChange?: Function;
    /** The currently selected classifiers */
    classifiers: classifiersStateData;
    /** The current parent value */
    parentValue?: boolean;
    /** The Label of the menu */
    menuLabel?: string;
    /** if roots are allowed to classify */
    allowRoots?: boolean;
    /** if multiple classifiers can be selected */
    multiple?: boolean;
    /** The Zindex to the display the menu at (default 3) */
    zIndex?: number;
    /** If the app is mobile */
    mobile?: boolean;
    /** If the menu should call the handleChange function on each small change */
    alwaysHot?: boolean;
    /** if the menu should display the parent toggle on the top right */
    showParentToggle?: boolean;
}
function ClassifiersMenu({
    anchorRef,
    handleClose,
    menuLabel = 'Clasificar',
    allowRoots,
    classifiers,
    handleChange,
    zIndex = 3,
    parentValue,
    multiple = true,
    alwaysHot = false,
    showParentToggle = false,
    mobile = false,
    handleParentToggleChange,
}: ClassifiersMenuProps): JSX.Element {
    const siteInfo = useSelector((state: RootState) => state.site);

    const [currentClassifiers, setCurrentClassifiers] = useState(classifiers);

    const [info, setInfo] = useState<{
        roots: string[];
        classifiers: Record<string, SearchClassifier>;
    }>({
        roots: siteInfo.projectRoots,
        classifiers: {},
    });

    const [search, setSearch] = useState<string | undefined>(undefined);

    /** Classifier currently being classfied */
    const [classifyingObj, setClassifyingObj] = useState<
        ClassifierObj | undefined
    >(undefined);
    const emptyRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        /** Filter the classifiers with the search value, add the <SEARCH tag when
         *  value is found and store if has the searched value or children that do */
        let classifiers: Record<string, SearchClassifier> = {};
        if (search !== undefined) {
            const regex = new RegExp(search, 'gi');
            const clasifs = Object.values({ ...siteInfo }.classifiers);
            for (let i = 0; i < clasifs.length; i++) {
                const cla = clasifs[i];
                if (cla.idProject === siteInfo.idProject) {
                    const element = {
                        ...info.classifiers[cla._id],
                        ...cla,
                        hasSearch: false,
                        hasSearchInChild: false,
                    };
                    let found = false;
                    if (regex.test(element.name)) {
                        element.name = element.name.replace(
                            regex,
                            (match) => `<SEARCH:${match}>`
                        );
                        found = true;
                    }
                    if (regex.test(element.description)) {
                        element.description = element.description.replace(
                            regex,
                            (match) => `<SEARCH:${match}>`
                        );
                        found = true;
                    }
                    if (found) {
                        element.hasSearch = true;
                        if (element.path) {
                            for (const p of element.path) {
                                if (classifiers[p]) {
                                    classifiers[p] = {
                                        ...classifiers[p],
                                        hasSearchInChild: true,
                                    };
                                } else {
                                    classifiers[p] = {
                                        ...(info.classifiers[p] ??
                                            newClassifier(
                                                siteInfo.classifiers[p]
                                            )),
                                        hasSearchInChild: true,
                                    };
                                }
                            }
                        }
                    }
                    classifiers[element._id] = element;
                }
            }
            setInfo({
                ...info,
                classifiers,
            });
        } else {
            /** Filter valid classifers */
            const clasifs = Object.values({ ...siteInfo }.classifiers);
            for (const cla of clasifs) {
                if (cla.idProject === siteInfo.idProject) {
                    classifiers[cla._id] = {
                        ...info.classifiers[cla._id],
                        name: cla.name,
                        description: cla.description,
                        hasSearch: false,
                        hasSearchInChild: false,
                    };
                }
            }
        }
        setInfo({
            roots: siteInfo.projectRoots,
            classifiers,
        });
    }, [search]);

    useEffect(() => {
        const classifiers = info.classifiers;
        const clasifs = Object.values({ ...siteInfo }.classifiers);
        let deactivates: Record<string, string[]> = {};
        for (const element of clasifs) {
            if (element.idProject === siteInfo.idProject) {
                classifiers[element._id] = newClassifier(element);
                if (element.active === false) {
                    const parent = element.path?.[element.path.length - 1];
                    if (parent) {
                        if (deactivates[parent]) {
                            deactivates[parent].push(element._id);
                        } else {
                            deactivates[parent] = [element._id];
                        }
                    }
                }
            }
        }
        for (const parent in deactivates) {
            const parentElement = classifiers[parent];
            if (parentElement) {
                parentElement.children = [
                    ...parentElement.children,
                    ...deactivates[parent],
                ];
            }
        }
        // convert ids to classifiers
        for (const idRoot of Object.keys(currentClassifiers)) {
            const idClassifiers = currentClassifiers[idRoot];
            for (const idClassifier of idClassifiers.idPathSelected) {
                const element = classifiers[idClassifier];
                const originalE = { ...element };
                element.state = ClassifierState.selected;
                element.pathFull = true;
                element.countFullSelected = element.countChildren;
                element.countFullEmpty = 0;
                element.legacySameState = true;
                extendHerarchyToChildrens(element, { classifiers });
                updateParentOnSonChange(element, { classifiers }, originalE);
            }
            for (const idClassifier of idClassifiers.idPathExcluded) {
                const element = classifiers[idClassifier];
                const originalE = { ...element };
                element.state = ClassifierState.excluded;
                element.pathFull = true;
                element.legacySameState = true;
                element.countFullExcluded = element.countChildren;
                element.countFullEmpty = 0;
                extendHerarchyToChildrens(element, { classifiers });
                updateParentOnSonChange(element, { classifiers }, originalE);
            }
            for (const idClassifier of idClassifiers.idValuesSelected) {
                const element = classifiers[idClassifier];
                const originalE = { ...element };
                element.state = ClassifierState.selected;
                element.pathFull = false;
                element.legacySameState = false;
                element.countFullEmpty = element.countChildren;
                updateParentOnSonChange(element, { classifiers }, originalE);
            }
            for (const idClassifier of idClassifiers.idValuesExcluded) {
                const element = classifiers[idClassifier];
                const originalE = { ...element };
                element.state = ClassifierState.excluded;
                element.pathFull = false;
                element.legacySameState = false;
                element.countFullEmpty = element.countChildren;
                updateParentOnSonChange(element, { classifiers }, originalE);
                fixExcludedSonChangeSelectedToEmpty(element, { classifiers });
            }
        }
        setInfo({ ...info, classifiers });
    }, []);

    const extendHerarchyToChildrens = (
        element: SearchClassifier,
        info: {
            classifiers: Record<string, SearchClassifier>;
        }
    ): void => {
        for (const child of element.children) {
            const childElement =
                info.classifiers[child] ??
                (info.classifiers[child] = newClassifier(
                    siteInfo.classifiers[child]
                ));
            childElement.state = element.state;
            childElement.pathFull = true;
            childElement.legacySameState = true;
            childElement.countFullSelected = 0;
            childElement.countFullExcluded = 0;
            childElement.countFullEmpty = 0;
            childElement.countFragmentSelected = 0;
            childElement.countFragmentExcluded = 0;
            if (element.state === ClassifierState.selected) {
                childElement.countFullSelected = childElement.countChildren;
            } else if (element.state === ClassifierState.excluded) {
                childElement.countFullExcluded = childElement.countChildren;
            } else if (element.state === ClassifierState.empty) {
                childElement.countFullEmpty = childElement.countChildren;
            }
            extendHerarchyToChildrens(childElement, info);
        }
    };

    const fixExcludedSonChangeSelectedToEmpty = (
        element: SearchClassifier,
        info: {
            classifiers: Record<string, SearchClassifier>;
        }
    ): void => {
        if (element.state !== ClassifierState.excluded) return;
        for (const child of element.children) {
            const childElement = info.classifiers[child];
            if (childElement.state === ClassifierState.selected) {
                childElement.state = ClassifierState.empty;
                childElement.countFullEmpty = childElement.countChildren;
                childElement.countFullSelected = 0;
                childElement.countFullExcluded = 0;
                childElement.legacySameState = true;
                childElement.pathFull = true;
                fixExcludedSonChangeSelectedToEmpty(childElement, info);
            }
        }
    };

    const updateParentOnSonChange = (
        element: SearchClassifier,
        info: {
            classifiers: Record<string, SearchClassifier>;
        },
        originalSonState: {
            state: ClassifierState;
            legacySameState: boolean;
            pathFull: boolean;
        }
    ): void => {
        if (!element.path) return;
        const idParent = element.path[element.path.length - 1];
        let parent: SearchClassifier =
            info.classifiers[idParent] ??
            (info.classifiers[idParent] = newClassifier(element));
        // revisar si hubo un cambio en el estado del hijo
        if (
            originalSonState.state === element.state &&
            originalSonState.legacySameState === element.legacySameState &&
            originalSonState.pathFull === element.pathFull
        ) {
            return;
        }
        const originalParent = { ...parent };
        // si pasa de legacySameState a no legacySameState

        if (originalSonState.legacySameState) {
            updateCounts(parent, originalSonState.state, -1);
        } else if (originalSonState.pathFull === false) {
            updateCountsFragment(parent, originalSonState.state, -1);
        }
        if (element.legacySameState) {
            updateCounts(parent, element.state, 1);
        } else if (element.pathFull === false) {
            updateCountsFragment(parent, element.state, 1);
        }

        parent.legacySameState = calculateIsLegacySameState(parent);

        parent.pathFull =
            parent.state !== ClassifierState.selected
                ? parent.legacySameState
                : parent.legacySameState ||
                  (parent.countFragmentEmpty === 0 &&
                      parent.countFragmentSelected === 0 &&
                      parent.countFullEmpty === 0);
        parent.countFullSelected === 0 && parent.countFragmentSelected === 0;

        updateParentOnSonChange(parent, info, originalParent);
    };

    const updateCounts = (
        target: SearchClassifier,
        state: ClassifierState,
        factor: 1 | -1
    ): void => {
        if (state === ClassifierState.selected) {
            target.countFullSelected += factor;
        } else if (state === ClassifierState.excluded) {
            target.countFullExcluded += factor;
        } else if (state === ClassifierState.empty) {
            target.countFullEmpty += factor;
        }
    };

    const calculateIsLegacySameState = (element: SearchClassifier): boolean => {
        return (
            (element.state === ClassifierState.excluded &&
                element.countFullExcluded === element.countChildren) ||
            (element.state === ClassifierState.selected &&
                element.countFullSelected === element.countChildren) ||
            (element.state === ClassifierState.empty &&
                element.countFullEmpty === element.countChildren)
        );
    };

    const updateCountsFragment = (
        target: SearchClassifier,
        state: ClassifierState,
        factor: 1 | -1
    ): void => {
        if (state === ClassifierState.selected) {
            target.countFragmentSelected += factor;
        } else if (state === ClassifierState.excluded) {
            target.countFragmentExcluded += factor;
        } else if (state === ClassifierState.empty) {
            target.countFragmentEmpty += factor;
        }
    };

    const verifyRecursively = (
        element: SearchClassifier,
        rootInfo: classifiersStateData[0],
        parentFull = false
    ): void => {
        let elementFull = false;
        if (element.state === ClassifierState.selected) {
            if (element.pathFull && !parentFull) {
                rootInfo.idPathSelected.push(element._id);
                elementFull = true;
            } else if (!element.pathFull) {
                rootInfo.idValuesSelected.push(element._id);
            }
        } else if (element.state === ClassifierState.excluded) {
            if (element.pathFull) {
                rootInfo.idPathExcluded.push(element._id);
            } else {
                rootInfo.idValuesExcluded.push(element._id);
            }
        }

        if (!element.legacySameState) {
            // si es selected y pathFull revisamos los hijos que no sean selected y legacysamestate, de resto todos
            const children = element.children;
            if (
                element.state === ClassifierState.selected &&
                element.pathFull
            ) {
                for (const child of children) {
                    const childElement = info.classifiers[child];
                    if (
                        childElement.state !== ClassifierState.selected ||
                        !childElement.legacySameState
                    ) {
                        verifyRecursively(childElement, rootInfo, elementFull);
                    }
                }
            } else if (
                element.state === ClassifierState.excluded &&
                !element.pathFull
            ) {
                for (const child of children) {
                    const childElement = info.classifiers[child];
                    if (
                        !(
                            childElement.state === ClassifierState.selected &&
                            childElement.legacySameState
                        )
                    ) {
                        verifyRecursively(childElement, rootInfo);
                    }
                }
            } else {
                for (const child of children) {
                    const childElement = info.classifiers[child];
                    verifyRecursively(childElement, rootInfo);
                }
            }
        }
    };

    const transformToClassifiers = (
        idRoot: string
    ): classifiersStateData[0] | undefined => {
        const selectedClassifier = info.classifiers;
        const rootInfo = {
            idValuesSelected: new Array<string>(),
            idPathSelected: new Array<string>(),
            idValuesExcluded: new Array<string>(),
            idPathExcluded: new Array<string>(),
        };
        const element = selectedClassifier[idRoot];
        verifyRecursively(element, rootInfo);

        // objecto para debuggear misma estructura que el state pero con los nombres
        // const rootInfoDebug = {
        //     idValuesSelected: rootInfo.idValuesSelected.map(
        //         (id) => selectedClassifier[id].name
        //     ),
        //     idPathSelected: rootInfo.idPathSelected.map(
        //         (id) => selectedClassifier[id].name
        //     ),
        //     idValuesExcluded: rootInfo.idValuesExcluded.map(
        //         (id) => selectedClassifier[id].name
        //     ),
        //     idPathExcluded: rootInfo.idPathExcluded.map(
        //         (id) => selectedClassifier[id].name
        //     ),
        // };
        if (
            rootInfo.idValuesSelected.length === 0 &&
            rootInfo.idPathSelected.length === 0 &&
            rootInfo.idValuesExcluded.length === 0 &&
            rootInfo.idPathExcluded.length === 0
        ) {
            return undefined;
        }
        return rootInfo;
    };

    const calcParentContextState = (
        element: SearchClassifier
    ): ClassifierState => {
        const idParent = element.path?.[element.path.length - 1];
        if (!idParent) return ClassifierState.empty;
        const parent = info.classifiers[idParent];
        if (parent.state === ClassifierState.empty)
            return calcParentContextState(parent);
        return parent.state;
    };

    /**
     * Function that stores a new change in the dictionary of changes
     */
    const change = (
        classifier: TicketClassifier,
        hotRefresh: boolean
    ): void => {
        if (multiple) {
            // verify if the classifier is already selected
            const element =
                info.classifiers[classifier.idValue ?? classifier.idRoot];
            const originalElement = { ...element };
            element.countFullSelected = 0;
            element.countFullExcluded = 0;
            element.countFullEmpty = 0;
            element.countFragmentSelected = 0;
            element.countFragmentExcluded = 0;
            element.countFragmentEmpty = 0;
            element.legacySameState = true;
            element.pathFull = true;
            if (element.state === ClassifierState.selected) {
                // pasa a excluded
                element.state = ClassifierState.excluded;
                element.countFullExcluded = element.countChildren;
            } else if (element.state === ClassifierState.excluded) {
                // pasa a empty
                element.state = ClassifierState.empty;
            } else {
                const parentContext = calcParentContextState(element);
                if (parentContext === ClassifierState.excluded) {
                    element.state = ClassifierState.excluded;
                    element.countFullExcluded = element.countChildren;
                } else {
                    element.state = ClassifierState.selected;
                    element.countFullSelected = element.countChildren;
                }
            }
            extendHerarchyToChildrens(element, info);
            updateParentOnSonChange(element, info, originalElement);
            const infoRoot = transformToClassifiers(classifier.idRoot);
            if (infoRoot) {
                setCurrentClassifiers({
                    ...currentClassifiers,
                    [classifier.idRoot]: infoRoot,
                });
            } else {
                delete currentClassifiers[classifier.idRoot];
                setCurrentClassifiers({ ...currentClassifiers });
            }
        } else {
            if (hotRefresh || alwaysHot) {
                handleChange([classifier]);
            }
            if (classifier.idValue !== null) {
                setCurrentClassifiers({
                    [classifier.idRoot]: {
                        idValuesSelected: [],
                        idPathSelected: [classifier.idValue],
                        idValuesExcluded: [],
                        idPathExcluded: [],
                    },
                });
            } else {
                setCurrentClassifiers({});
            }
        }
    };

    const renderOptions = (): JSX.Element | void => {
        if (classifyingObj === undefined) {
            return (
                <Classifiers
                    searching={search !== undefined}
                    setClassifyingObj={setClassifyingObj}
                    change={change}
                    info={info}
                    search={search}
                    allowRoots={allowRoots}
                />
            );
        }
        return (
            <Classifying
                searching={search !== undefined}
                classifyingObj={classifyingObj}
                setClassifyingObj={setClassifyingObj}
                change={change}
                info={info}
                search={search}
                allowRoots={allowRoots}
            />
        );
    };

    const renderContent = (): JSX.Element => {
        return (
            <div
                className={
                    mobile ? styles.mobileContainer : styles.emptyContainer
                }
                onClick={(e): void => {
                    if (emptyRef.current === e.target) {
                        handleChange(currentClassifiers);
                        handleClose(currentClassifiers);
                    }
                }}
                ref={emptyRef}
            >
                <div className={styles.container}>
                    <div className={styles.title}>
                        {menuLabel}
                        {showParentToggle && (
                            <div className={styles.transferBtn + ' noselect'}>
                                Agrupar:
                                <div className={styles.transferToggle}>
                                    <Toggle
                                        size={'small'}
                                        checked={parentValue === true}
                                        onChange={(): void => {
                                            handleParentToggleChange?.(
                                                parentValue !== true
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={styles.searchContainer}>
                        <Search
                            wait={500}
                            placeholder={'Filtrar clasificadores'}
                            search={search}
                            handleSearch={(value: any): void => {
                                if (value.trim() === '') {
                                    setSearch(undefined);
                                } else {
                                    setSearch(value);
                                }
                            }}
                        />
                    </div>
                    {renderOptions()}
                </div>
            </div>
        );
    };
    if (mobile) {
        return (
            <Dialog
                onClose={(): void => {
                    handleChange(currentClassifiers);
                    handleClose(currentClassifiers);
                }}
                maxWidth="100vw"
                border={10}
                transparent
            >
                {renderContent()}
            </Dialog>
        );
    } else {
        return (
            <ClickAwayListener
                mouseEvent="onMouseDown"
                onClickAway={(): void => {
                    handleChange(currentClassifiers);
                    handleClose(currentClassifiers);
                }}
            >
                <Popper
                    open={true}
                    anchorEl={anchorRef.current}
                    placement={'bottom-start'}
                    modifiers={{
                        preventOverflow: {
                            enabled: true,
                            priority: ['top', 'bottom', 'left', 'right'],
                            boundariesElement: 'window',
                        },
                    }}
                    style={{ zIndex }}
                >
                    {renderContent()}
                </Popper>
            </ClickAwayListener>
        );
    }
}
export default ClassifiersMenu;

const colorToState = {
    [ClassifierState.selected]: 'var(--accent)',
    [ClassifierState.excluded]: 'var(--error)',
    [ClassifierState.empty]: 'var(--contrast)',
};

export function renderClassifierIcon(element: SearchClassifier): JSX.Element {
    const color = colorToState[element.state];
    return (
        <>
            {element.state === ClassifierState.selected &&
                element.legacySameState && (
                    <CheckRoundedIcon fontSize="inherit" style={{ color }} />
                )}
            {element.state === ClassifierState.excluded &&
                element.legacySameState && (
                    <CloseRoundedIcon fontSize="inherit" style={{ color }} />
                )}
            {!element.legacySameState && (
                <RemoveRoundedIcon fontSize="inherit" style={{ color }} />
            )}
        </>
    );
}

export const newClassifier = (element: Classifier): SearchClassifier => {
    return {
        ...element,
        state: ClassifierState.empty,
        countChildren: element.children.length,
        countFullSelected: 0,
        countFullExcluded: 0,
        countFullEmpty: element.children.length,
        countFragmentSelected: 0,
        countFragmentExcluded: 0,
        countFragmentEmpty: 0,
        legacySameState: true,
        pathFull: true,
    };
};
