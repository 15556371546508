import React, { cloneElement, useState } from 'react';
import styles from './Sidebar.module.css';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import {
    SideBarConfig,
    SideBarConfigRoute,
} from '../../constants/SidebarConfigs';
import { RootState } from '../../utils/_store';
import { useSelector } from 'react-redux';
import {
    EntityPermissions,
    OrganizationPermissions,
    ProjectPermissions,
} from '../../constants/Permissions';
import {
    checkEntityPerm,
    checkOrgPerm,
    checkProjPerm,
} from '../../utils/PermissionsFunctions';
import { Drawer } from '@material-ui/core';
import EntityIcon from '../SmartIcons/EntityIcon';
import { Project } from '../../@Types/Project';
import { useCurrentProject } from '../../hooks';
interface SideBarProps {
    /**  The Sidebar Configuration */
    config: SideBarConfig;
    /**  If the routes are absolute or relative */
    absoluteRouting?: boolean;
    /** If the app is currentle mobile */
    mobile: boolean;
}

function SideBar({
    config,
    absoluteRouting = false,
    mobile,
}: SideBarProps): JSX.Element {
    /**Site info */
    const user = useSelector((state: RootState) => state.site.user);
    const organization = useSelector(
        (state: RootState) => state.site.organization
    );
    const project = useCurrentProject();
    const siteEntities = useSelector((state: RootState) => state.site.entities);
    /** Indicates if the drawer should be open*/
    const [open, setOpen] = useState(false);
    /** Indicates if button info shoud be showing*/
    const [show, setShow] = useState(false);
    /** Disabled State so that is closes on click */
    const [disabled, setDisabled] = useState(false);
    /** The current path */
    let { url } = useRouteMatch();
    const { pathname } = useLocation();

    const handleClick = async (): Promise<void> => {
        setOpen(false);
        setDisabled(true);
        await new Promise((r) => setTimeout(r, 500));
        setDisabled(false);
        setShow(false);
    };

    const mapRoutes = (): JSX.Element => {
        let elements: JSX.Element[] = [];
        for (const route of config?.routes) {
            const to = absoluteRouting ? route.location : url + route.location;
            if (route.key === 'ENTITIES') {
                const entities = Object.values(siteEntities).filter(
                    (entity) =>
                        entity._id !== organization?.idClientEntity &&
                        entity._id !== organization?.idCompanyEntity &&
                        entity.active !== false
                );
                entities.sort((a, b) => a.name.localeCompare(b.name));
                for (const entity of entities) {
                    if (
                        checkEntityPerm(
                            user,
                            entity._id,
                            EntityPermissions.VIEW
                        )
                    ) {
                        elements.push(
                            <SideBarLink
                                to={'/entities/' + entity._id}
                                route={{
                                    ...route,
                                    label: entity.pluralName,
                                }}
                                project={project}
                                key={entity._id}
                                show={show}
                                onClick={handleClick}
                                customIcon={
                                    <EntityIcon idEntity={entity._id} />
                                }
                            />
                        );
                    }
                }
            } else if (
                route.permissions === undefined ||
                route.permissions.find((permission) =>
                    checkOrgPerm(
                        user,
                        organization,
                        permission as OrganizationPermissions
                    )
                ) ||
                route.permissions.find((permission) =>
                    checkProjPerm(
                        user,
                        project?._id,
                        permission as ProjectPermissions
                    )
                )
            ) {
                elements.push(
                    <SideBarLink
                        to={to}
                        route={route}
                        project={project}
                        key={route.location}
                        show={show}
                        onClick={handleClick}
                    />
                );
            }
        }
        return <React.Fragment>{elements}</React.Fragment>;
    };
    if (mobile) {
        let route = config.routes.find((route) =>
            pathname.startsWith(
                absoluteRouting ? route.location : url + route.location
            )
        );
        let customIcon: undefined | JSX.Element;
        if (route?.key === 'ENTITIES') {
            const entity = Object.values(siteEntities).find((entity) =>
                pathname.includes(entity._id)
            );
            if (entity) {
                route = {
                    ...route,
                    label: entity.pluralName,
                };
                customIcon = <EntityIcon idEntity={entity._id} />;
            }
        }
        if (route) {
            return (
                <React.Fragment>
                    <Drawer
                        anchor={'left'}
                        open={open}
                        onClose={(): void => {
                            setOpen(false);
                            setShow(false);
                        }}
                    >
                        <div className={styles.minifiedSideBarDrawer}>
                            {mapRoutes()}
                        </div>
                    </Drawer>
                    <div className={styles.minifiedContainer}>
                        <SideBarLink
                            to={
                                absoluteRouting
                                    ? route.location
                                    : url + route.location
                            }
                            route={route}
                            project={project}
                            mini
                            show={false}
                            onClick={(e: any): void => {
                                e.preventDefault();
                                setOpen(true);
                                setShow(true);
                            }}
                            customIcon={customIcon}
                        />
                    </div>
                </React.Fragment>
            );
        } else {
            return <div></div>;
        }
    } else {
        return (
            <nav
                className={styles.sideBarContainer + ' flexbox'}
                style={{ width: 60 }}
                onMouseOver={(): void => {
                    if (!disabled) {
                        setOpen(true);
                        setShow(true);
                    }
                }}
                onMouseLeave={async (): Promise<void> => {
                    if (!disabled) {
                        setOpen(false);
                        await new Promise((r) => setTimeout(r, 500));
                        setShow(false);
                    }
                }}
            >
                <div
                    style={{
                        maxWidth: open ? 300 : 60,
                        transition: 'max-width 0.5s',
                    }}
                    className={styles.sideBarDrawer}
                >
                    {mapRoutes()}
                </div>
            </nav>
        );
    }
}

export default SideBar;

interface SideBarLinkProps {
    /** Link url for the router */
    to: string;
    /** Config route info */
    route: SideBarConfigRoute;
    /** If buttons should be rendered */
    show: boolean;
    /** If the button is the minified Version */
    mini?: boolean;
    /** Handles ButtonClick */
    onClick: React.MouseEventHandler<HTMLAnchorElement>;
    /** The current siteInfo */
    project: Project;
    /** Custom icon to display */
    customIcon?: JSX.Element;
}

function SideBarLink({
    to,
    show,
    route,
    mini,
    onClick,
    project,
    customIcon,
}: SideBarLinkProps): JSX.Element {
    const classes = [styles.containerElement];
    let match = useRouteMatch({
        path: to,
    });
    if (match) {
        classes.push(styles.selectedElement);
    }
    if (route.key === 'FLOW' && project !== undefined) {
        if (project) {
            to += '/' + project.idFlow;
        }
    }
    if (route.key === 'CONFIGS') {
        classes.push(styles.lastElement);
    }
    if (mini) {
        classes.push(styles.minifiedElement);
    } else {
        classes.push(
            show ? styles.openDesktopElement : styles.closedDesktopElement
        );
    }
    let icon = route.icon;
    if (!icon.includes('.')) {
        icon += '.svg';
    }
    icon = '/media/icons/' + icon;
    return (
        <Link to={to} className={classes.join(' ')} onClick={onClick}>
            {customIcon ? (
                <div className={styles.elementIcon}>
                    {cloneElement(customIcon, {
                        fill: 'white',
                        style: mini
                            ? { width: 22, height: 22 }
                            : {
                                  width: 35,
                                  height: 35,
                              },
                    })}
                </div>
            ) : (
                <img
                    alt={route.label}
                    className={styles.elementIcon}
                    src={icon}
                ></img>
            )}
            <div className={styles.elementLabel}>{route.label}</div>
        </Link>
    );
}
