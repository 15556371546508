import styles from './FileUploadStep.module.css';
import RoundedTextField from '../../RoundedTextField/RoundedTextField';
import React, { cloneElement } from 'react';
import RoundedButton from '../../RoundedButton/RoundedButton';
import { GenericStepProps } from '../../GenericFormEditor/Step/Step';
import { calcStepWidth } from '../../GenericFormEditor/StepFunctions';
import { GLocation } from '../../../@Types/FormTypes/LocationTypes';
import { GFileUpload } from '../../../@Types/GenericFormSteps';

function FileUploadStep<S extends GFileUpload, U, L extends GLocation>({
    size,
    step,
    editing,
    children,
    updateStep,
    uniqueSteps,
    updateUniqueSteps,
}: GenericStepProps<S, U, L> & {
    /** children to render */
    children?: React.ReactElement<any, string>;
}): JSX.Element {
    if (editing) {
        return (
            <div className={styles.editingContainer}>
                <div className={styles.inputContainer}>
                    <RoundedTextField
                        label="Etiqueta"
                        value={step.label}
                        onChange={(e): void => {
                            updateStep({ ...step, label: e.target.value });
                        }}
                    ></RoundedTextField>
                </div>
                <RoundedTextField
                    label="Descripción"
                    value={step.description ?? ''}
                    onChange={(e): void => {
                        updateStep({
                            ...step,
                            description: e.target.value,
                        });
                    }}
                ></RoundedTextField>
                {children &&
                    cloneElement(children, {
                        step,
                        updateStep,
                        uniqueSteps,
                        updateUniqueSteps,
                    })}
            </div>
        );
    } else {
        return (
            <div
                className={styles.prevContainer}
                style={{
                    width: calcStepWidth(4, size),
                    maxWidth: '100%',
                }}
            >
                <div className={styles.labelLabel}>{step.label}</div>
                {step.description && (
                    <div className={styles.stepDescriptionLabel}>
                        {step.description}
                    </div>
                )}
                <div className={styles.btnContainer}>
                    <RoundedButton
                        text={'Examinar' + (step.required ? ' *' : '')}
                        color={'white'}
                        fontSize={16}
                        padding={'5px 15px 5px 15px'}
                    />
                </div>
            </div>
        );
    }
}

export default React.memo(FileUploadStep, (prev, next) => {
    const { step, stepEqualityChecker } = prev;
    const { step: newStep } = next;
    if (
        step.label !== newStep.label ||
        step.required !== newStep.required ||
        step.description !== newStep.description
    ) {
        return false;
    }

    return stepEqualityChecker?.(prev, next) ?? true;
}) as typeof FileUploadStep;
