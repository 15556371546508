import styles from './Classifiers.module.css';
import ClassifierComponent from './Classifier/Classifier';
import { SearchClassifier } from '../ClassifyMenu';
interface ClassifiersProps {
    /** Function to call when the user wants to change something. */
    change: Function;
    /** Function to change the value of the current classifier bieng classified */
    setClassifyingObj: Function;
    /** The roots and classifiers available to display */
    info: { roots: string[]; classifiers: Record<string, SearchClassifier> };
    /** If the user is searching */
    searching: boolean;
    /** the value of current search */
    search: string | undefined;
    /** if roots are allowed to classify */
    allowRoots?: boolean;
    /** noBorderTop */
    noBorderTop?: boolean;
}
function Classifiers({
    change,
    setClassifyingObj,
    noBorderTop = false,
    info,
    searching,
    search,
}: ClassifiersProps): JSX.Element {
    //Search, filter only roots with search info.
    const roots = search
        ? info.roots.filter(
              (root) =>
                  info.classifiers[root].hasSearch ||
                  info.classifiers[root].hasSearchInChild
          )
        : info.roots;
    if (roots.length > 0) {
        return (
            <div
                className={styles.container}
                style={{
                    borderTop: noBorderTop
                        ? 'none'
                        : '1px solid var(--light-grey)',
                }}
            >
                {roots.map((idRoot, index) => (
                    <ClassifierComponent
                        searching={searching}
                        search={search}
                        key={idRoot}
                        last={index === roots.length - 1}
                        classifier={{ idRoot, idValue: idRoot }}
                        classifiers={info.classifiers}
                        handleClick={(): void => {
                            change({ idRoot, idValue: idRoot }, true);
                        }}
                        handleViewMore={(): void => {
                            setClassifyingObj({
                                idRoot,
                                current: idRoot,
                            });
                        }}
                    />
                ))}
            </div>
        );
    } else {
        return (
            <div
                className={styles.emptyContainer}
                style={{
                    borderTop: noBorderTop
                        ? 'none'
                        : '1px solid var(--light-grey)',
                }}
            >
                No hay clasificadores
            </div>
        );
    }
}
export default Classifiers;
