import styles from './UploadFileConfig.module.css';
import Toggle from '../../../../shared/Toggle/Toggle';
import React, { useMemo } from 'react';
import { FileUpload, FormStep } from '../../../../@Types/FormTypes/FormStep';
import { useAppSelector } from '../../../../hooks';
import EntityPropertyTypes from '../../../../constants/EntityPropertyTypes';
import RoundedClientPropertyPicker from '../../../../shared/@Pickers/RoundedClientPropertyPicker/RoundedClientPropertyPicker';

interface UploadFileConfigProps {
    step?: FileUpload;
    updateStep?: (step: FormStep) => void;
}
function UploadFileConfig({
    step,
    updateStep,
}: UploadFileConfigProps): JSX.Element {
    const clientEntity = useAppSelector((state) => state.site.clientEntity);

    const fileProperties = useMemo(
        () =>
            Object.keys(clientEntity?.steps ?? {}).filter(
                (key) =>
                    clientEntity?.steps[key]?.type ===
                    EntityPropertyTypes.FILEUPLOAD
            ),
        [clientEntity?.steps]
    );

    if (!step || !updateStep) {
        return <div></div>;
    }

    return (
        <>
            {fileProperties.length > 0 && (
                <div className={styles.clientContainer}>
                    <div className={styles.toggleLabelContainer}>
                        <div className={styles.clientLbl}>
                            Información del Cliente:
                        </div>
                        <div className={styles.toggleContainer}>
                            <Toggle
                                checked={step.idClientProperty !== undefined}
                                onChange={(checked: boolean): void => {
                                    const tempStep = checked
                                        ? {
                                              ...step,
                                              idClientProperty:
                                                  fileProperties[0],
                                          }
                                        : {
                                              ...step,
                                              idClientProperty: undefined,
                                          };
                                    updateStep(tempStep);
                                }}
                            />
                        </div>
                    </div>
                    {step.idClientProperty !== undefined && (
                        <React.Fragment>
                            <div className={styles.clientInfoContainer}>
                                <div className={styles.clientInfoLabel}>
                                    Atributo:{' '}
                                </div>
                                <div
                                    className={
                                        styles.clientInfoSelectorContainer
                                    }
                                >
                                    <RoundedClientPropertyPicker
                                        height="31px"
                                        fullWidth
                                        label={''}
                                        MenuProps={{ disablePortal: true }}
                                        value={step.idClientProperty}
                                        propertyTypes={[
                                            EntityPropertyTypes.FILEUPLOAD,
                                        ]}
                                        handleUpdate={(
                                            idClientProperty
                                        ): void => {
                                            updateStep({
                                                ...step,
                                                idClientProperty,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            )}
        </>
    );
}
export default UploadFileConfig;
