import styles from './StepDetail.module.css';
import CBRStepTypes from '../../../../constants/Construction/CBRFormStepTypes';
import React, { useEffect, useState } from 'react';
import StepTypes from '../../../../constants/Conversations/ConversationStepTypes';
import { RootState } from '../../../../utils/_store';
import { useDispatch, useSelector } from 'react-redux';
import InfoTextStep from './Informative/InfoTextStep/InfoTextStep';
import CreateStep from './CreateStep/CreateStep';
import {
    copyStep,
    deleteStep,
    updateStep,
    updateStepId,
} from '../../../../controllers/ConversationEditorController/ConversationEditorActions';
import EditContainer from '../../../../shared/EditContainer/EditContainer';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import TextStep from './Collection/TextStep/TextStep';
import FileStep from './Collection/FileStep/FileStep';
import OpenStep from './Collection/OpenStep/OpenStep';
import InfoImageStep from './Informative/InfoImageStep/InfoImageStep';
import InfoStickerStep from './Informative/InfoStickerStep/InfoStickerStep';
import InfoDocumentStep from './Informative/InfoDocumentStep/InfoDocumentStep';
import InfoVideoStep from './Informative/InfoVideoStep/InfoVideoStep';
import InfoAudioStep from './Informative/InfoAudioStep/InfoAudioStep';
import ButtonsStep from './Collection/ButtonsStep/ButtonsStep';
import ListStep from './Collection/ListStep/ListStep';
import { ConversationStep } from '../../../../@Types/ConversationTypes/ConversationStep';
import RoundedTextField from '../../../../shared/RoundedTextField/RoundedTextField';
import RoundedButton from '../../../../shared/RoundedButton/RoundedButton';
import ListPageStep from './Collection/ListPageStep/ListPageStep';
import CBRStepMapper from './CBRStepMapper';
import FilterIcon from '../../../../Icons/FilterIcon';
import Condition from './Condition/Condition';
import AYFStepMapper from './AYFStepMapper';
import { OrgIntegrations } from '../../../../@Types/Organization';
import EntityValueStep from './Collection/EntityValueStep/EntityValueStep';
import CreatableStep from './Collection/CreatableStep/CreatableStep';
import StepConfigBtn from './Configs/StepConfigBtn';
import { checkAdmin } from '../../../../utils/PermissionsFunctions';
import LinkStep from './Informative/LinkStep/LinkStep';
import NavBackStep from './Navigation/NavBackStep/NavBackStep';
import NavExitStep from './Navigation/NavExitStep/NavExitStep';
import DisabledIcon from '../../../../Icons/DisabledIcon';
import ListApiStepComponent from './Collection/ListApiStep/ListApiStep';

function StepDetail(): JSX.Element {
    const [showConditions, setShowConditions] = useState(false);
    const dispatch = useDispatch();
    const step = useSelector(
        (state: RootState) =>
            state.conversationEditor.steps[
                state.conversationEditor.selectedStep?.id ?? ''
            ]
    );

    const controlled = useSelector((state: RootState) => {
        const idStep = state.conversationEditor.selectedStep?.id ?? '';
        const idParent =
            state.conversationEditor.stepDependencies[idStep]?.idParent ?? '';
        const parentStep = state.conversationEditor.steps[idParent] as any;
        return (
            parentStep?.type === CBRStepTypes.CBR_LOCATIVAS ||
            state.conversationEditor.idCreate === idStep
        );
    });

    const user = useSelector((state: RootState) => state.site.user);

    const integrations = useSelector(
        (state: RootState) => state.site.organization!.integrations
    );

    if (!step) {
        return <div className={styles.emptyDetail}>Selecciona un Mensaje</div>;
    }

    return (
        <React.Fragment key={step.id}>
            {showConditions && (
                <Condition
                    idStep={step.id}
                    title={step.name}
                    condition={step.condition}
                    handleClose={(): void => {
                        setShowConditions(false);
                    }}
                    handleUpdate={(condition): void => {
                        dispatch(
                            updateStep({
                                ...step,
                                condition,
                            })
                        );
                    }}
                />
            )}
            <div className={styles.container}>
                <div className={styles.contentContainer}>
                    <div className={styles.titleContainer}>
                        <div className={styles.title}>
                            <div className={styles.editorContainer}>
                                <div className={styles.editor}>
                                    <EditContainer
                                        object={step}
                                        field={'name'}
                                        handleConfirm={(
                                            _object: any,
                                            _field: string,
                                            value: any
                                        ): void => {
                                            dispatch(
                                                updateStep({
                                                    ...step,
                                                    name: value,
                                                })
                                            );
                                        }}
                                    >
                                        <div className={styles.titlelbl}>
                                            {step.name}
                                        </div>
                                        <input
                                            className={
                                                styles.nameInput + ' edit-input'
                                            }
                                            defaultValue={step.name}
                                            type="text"
                                            size={10}
                                        />
                                    </EditContainer>
                                </div>
                            </div>
                            <div className={styles.btnsContainer}>
                                {!controlled && (
                                    <div
                                        className={styles.btn + ' grey-btn'}
                                        onClick={(): void => {
                                            dispatch(
                                                updateStep({
                                                    ...step,
                                                    disabled: !step.disabled,
                                                })
                                            );
                                        }}
                                        title={
                                            step.disabled
                                                ? 'Habilitar'
                                                : 'Deshabilitar'
                                        }
                                    >
                                        <DisabledIcon
                                            style={{
                                                width: 28,
                                                height: 28,
                                            }}
                                            fill={
                                                step.disabled
                                                    ? 'var(--error)'
                                                    : 'var(--dark-grey)'
                                            }
                                        />
                                    </div>
                                )}
                                {!controlled && (
                                    <StepConfigBtn
                                        step={step}
                                        handleUpdate={(step): void => {
                                            dispatch(updateStep(step));
                                        }}
                                    />
                                )}
                                {!controlled && (
                                    <div
                                        className={styles.btn + ' grey-btn'}
                                        onClick={(): void => {
                                            setShowConditions(true);
                                        }}
                                        title="Condiciones"
                                    >
                                        <FilterIcon
                                            style={{
                                                display: 'flex',
                                                height: 18,
                                                width: 18,
                                                marginTop: 2,
                                            }}
                                            fill={
                                                step.condition
                                                    ? 'var(--secondary)'
                                                    : 'var(--dark-grey)'
                                            }
                                        />
                                    </div>
                                )}
                                {!controlled && (
                                    <div
                                        className={styles.btn + ' grey-btn'}
                                        onClick={(): void => {
                                            dispatch(copyStep(step.id));
                                        }}
                                        title="Copiar"
                                    >
                                        <FileCopyRoundedIcon fontSize="inherit" />
                                    </div>
                                )}
                                {!controlled && (
                                    <div
                                        className={styles.btn + ' grey-btn'}
                                        onClick={(): void => {
                                            dispatch(deleteStep(step.id));
                                        }}
                                        title="Borrar"
                                    >
                                        <DeleteRoundedIcon fontSize="inherit" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <StepDetailMapper step={step} integrations={integrations} />
                    {checkAdmin(user) && !controlled && <EditId step={step} />}
                    <div className={styles.padding}></div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default StepDetail;

export function StepDetailMapper({
    step,
    integrations,
}: {
    integrations: OrgIntegrations;
    step: ConversationStep;
}): JSX.Element {
    switch (step.type) {
        case StepTypes.INFO_TEXT_STEP:
            return <InfoTextStep step={step as any} />;
        case StepTypes.INFO_IMAGE_STEP:
            return <InfoImageStep step={step as any} />;
        case StepTypes.INFO_DOCUMENT_STEP:
            return <InfoDocumentStep step={step as any} />;
        case StepTypes.INFO_VIDEO_STEP:
            return <InfoVideoStep step={step as any} />;
        case StepTypes.INFO_AUDIO_STEP:
            return <InfoAudioStep step={step as any} />;
        case StepTypes.INFO_STICKER_STEP:
            return <InfoStickerStep step={step as any} />;
        case StepTypes.TEXT_STEP:
            return <TextStep step={step as any} />;
        case StepTypes.FILE_STEP:
            return <FileStep step={step as any} />;
        case StepTypes.OPEN_STEP:
            return <OpenStep step={step as any} />;
        case StepTypes.LIST_STEP:
            return <ListStep step={step as any} />;
        case StepTypes.LIST_PAGE_STEP:
            return <ListPageStep step={step as any} />;
        case StepTypes.LIST_API_STEP:
            return <ListApiStepComponent step={step as any} />;
        case StepTypes.BUTTONS_STEP:
            return <ButtonsStep step={step as any} />;
        case StepTypes.LINK_STEP:
            return <LinkStep step={step as any} />;
        case StepTypes.NAV_BACK_STEP:
            return <NavBackStep step={step as any} />;
        case StepTypes.NAV_EXIT_STEP:
            return <NavExitStep step={step as any} />;
        case StepTypes.CREATE_DEFAULT:
        case StepTypes.CREATE_PASSTHROUGH:
            return <CreateStep step={step as any} />;
        case StepTypes.ENTITY_VALUE_STEP:
            return <EntityValueStep step={step as any} />;
        case StepTypes.CREATABLE_STEP:
            return <CreatableStep step={step as any} />;
        default:
            if (integrations.CBR && step.type.startsWith('CBR')) {
                return <CBRStepMapper step={step as any} />;
            }
            if (integrations.AYF && step.type.startsWith('AYF')) {
                return <AYFStepMapper step={step as any} />;
            }
            return <div>TODO</div>;
    }
}

export function EditId({ step }: { step: ConversationStep }): JSX.Element {
    const dispatch = useDispatch();
    const steps = useSelector(
        (state: RootState) => state.conversationEditor.steps
    );
    const [id, setId] = useState(step?.id);
    const [error, setError] = useState('');
    useEffect(() => {
        if (step && id !== step?.id) setId(step.id);
    }, [step]);

    return (
        <div className={styles.idEditor}>
            <div className={styles.idContainer + ' noselect'}>ID:</div>
            <div className={styles.idInputContainer}>
                <RoundedTextField
                    value={id}
                    required={true}
                    onChange={(event): void => {
                        setId(event.target.value);
                        if (event.target.value === '') {
                            setError('Este campo es obligatorio');
                        } else {
                            setError('');
                        }
                    }}
                    fontWeight={400}
                    backgroundColor="var(--primary1)"
                    helperText={error}
                    error={error != ''}
                ></RoundedTextField>
            </div>
            {id !== step.id && (
                <div className={styles.changeIdContainer}>
                    <RoundedButton
                        text="Cambiar"
                        onClick={(): void => {
                            const existing = steps[id] !== undefined;
                            if (existing) {
                                setError('El identificador debe ser único');
                            } else {
                                dispatch(
                                    updateStepId({
                                        idStep: step.id,
                                        newId: id,
                                    })
                                );
                            }
                        }}
                    />
                </div>
            )}
        </div>
    );
}
